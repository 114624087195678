const translation = {
  分析: '分析',
  所属经理: '所屬經理',
  投资策略: '投資策略',
  目标: '目標',
  状态: '狀態',
  建立日期: '建立日期',
  最近分析: '最近分析',
  风险概况: '風險概況',
  选择客户: '選擇客戶',
  刚刚: '剛剛',
  chat: {
    思考中: '思考中',
    思考秒: '思考 {{number}} 秒',
    推荐问: '\n推薦問:',
    消息异常: '消息異常',
  },
  index: {
    总任务数: '總任務數',
    总任务中的总用户数: '總任務中的總用戶數', 
    概览: '概覽',
    title: '資產變化',
    亏损客户数: '虧損賬戶',
    盈利客户数: '盈利賬戶',
    全部: '全部',
    亏损: '虧損',
    盈利: '盈利',
  },
  menus: {
    首页: '首頁',
    文档: '文檔',
    客户: '客戶',
    指导: '指導',
  },
  login: {
    欢迎标题: '外呼助手',
    选择角色: '請選擇一個角色，你是',
    登录描述: '歡迎使用我們的理財助手-與我們一起開啟您的財富管理之旅，體驗智能理財的魅力！',
    请输入用户名: '請輸入用戶名',
    请输入邮箱: '請輸入郵箱',
    获取验证码: '獲取驗證碼',
    请输入邮箱验证码: '請輸入郵箱驗證碼',
    请确认密码: '請確認密碼',
    FirstName: '性',
    LastName: '名',
    请输入手机号: '請輸入手機號',
    输入邮箱: '輸入您的郵箱',
    输入密码: '輸入您的密碼',
    请输入密码: '請輸入密碼',
    账号密码不正确: '郵箱或密碼不正確',
    注册账号: '註冊賬戶',
    登录: '登錄',
    注册: '註冊',
    不能为空: '必填',
    密码规则: '密碼必須包含至少8個字符，包含數字、字母和特殊字符',
    两次输入的密码不一致: '兩次輸入的密碼不一致',
    邮箱格式不正确: '郵箱格式不正確',
    注册失败: '註冊失敗',
    注册成功: "註冊成功",
    发送验证码失败: '發送驗證碼時出現錯誤，請稍後再試',
    注册失败1: "用戶名至少要 3 個字符長",
    注册失败2: "郵箱已存在",
    注册失败3: "用戶名已存在",
    注册失败4: "電話號碼已存在",
    注册失败5: "電話號碼必須是數字",
    注册失败6: "驗證碼錯誤",
  },
  task: {
    所有类型: '所有類型',
    类型1: '類型1',
    类型2: '類型2',
    所有状态: '所有狀態',
    状态1: '狀態1',
    状态2: '狀態2',
    搜索: '搜索',
    编号: '編號',
    呼叫名单: '呼叫名單',
    呼叫主题: '呼叫主題',
    类型: '類型',
    日期: '日期',
    推荐产品: '推薦產品',
    状态: '狀態',
    操作: '操作',
    新建任务: '新建任務',
    选择任务类型: '選擇任務類型',
    推销信用卡: '推銷信用卡',
    推销型产品: '推銷型產品',
    推销分期付款: '推銷分期付款',
    投资产品销售: '投資產品銷售',
    活动名称: '活動名稱',
    输入活动名称: '輸入活動名稱',
    取消: '取消',
    开始聊天: '開始聊天',
    待启动: '待啟動',
    进行中: '進行中',
    已完成: '已完成',
    已取消: '已取消',
    活动名称不能为空: '活動名稱不能為空',
    呼叫列表: '呼叫列表',
    客户ID: '客戶ID',
    客户姓名: '客戶姓名',
    联系电话: '聯絡電話',
    记录: '記錄',
    时间: '時間',
    拨打: '撥打',
  },
  call: {
    待拨打: '待撥打',
    通话中: '通話中',
    已结束: '已結束',
    未接通: '未接通',
    打印: '打印',
    下载: '下載',
    呼叫名单: '呼叫名單',
    用户详情: '用戶詳情',
    呼叫列表: '呼叫列表',
    呼叫编组: '呼叫編組',
    客户姓名: '客戶姓名',
    联系电话: '聯絡電話',
    客户ID: '客戶ID',
    通话小结: '通話小結',
    通话历史: '通話歷史',
  },
  userDetailTable: {
    投资类别: '投資類別',
    特定投资或证券的名称: '特定投資或證券的名稱',
    公开交易证券的股票代码或符号: '公開交易證券的股票代碼或符號',
    客户持有的股份或单位数量: '客戶持有的股份或單位數量',
    最初购买时的价格: '最初購買時的價格',
    当前市场价格: '當前市場價格',
    总价值: '總價值',
    原始成本: '原始成本',
    市场价值与成本基础之间的差额: '市場價值與成本基礎之間的差額',
    年收益率: '年收益率',
    投资收入: '投資收入',
    总资产分配给此资产的比例: '總資產分配給此資產的比例',
    风险水平: '風險水平',
    更新投资组合数据的日期: '更新投資組合數據的日期',
  },
  upload: {
    文件类型不支持: '文件類型不支持',
    文件太大: '文件過大，不能超過 {{size}}MB',
    上传成功: '上傳成功',
    上传限制个数: '批量上傳限制 {{number}} 個文件',
    拖拽文件至此或者: '拖拽文件到此，或',
    下载模板: '下載模板',
    下载用户数据: '下載用戶數據',
    下载理财数据: '下載理財數據',
    上传用户或理财信息: '上傳賬戶或財富數據',
    上传理财信息: '上傳理財信息',
    选择文件: '選擇文件',
    支持文件: '支持 {{exp}}，每個文件不能超過 {{size}}MB'
  },
  api: {
    success: '成功',
    actionSuccess: '操作成功',
    saved: '已保存',
    create: '已創建',
    remove: '已移除',
  },
}

export default translation
