'use client'

import { useSession } from "next-auth/react";
import { useEffect } from "react";
import { useRouter } from "next/navigation";
import { RoleType } from '~/globals.d'

export default function Home() {
  const router = useRouter()
  const { data: session } = useSession()
  useEffect(() => {
    if (session) {
      if (session?.user.role === RoleType.Account) {
        router.replace('/userInfo')
      } else {
        router.replace('/navCard')
      }
    } else {
      router.replace('/login')
    }
  }, [session])
  return (
  <></>
  );
}
