const translation = {
  分析: '분석',
  所属经理: '담당 매니저',
  投资策略: '투자 전략',
  目标: '목표',
  状态: '상태',
  建立日期: '생성일',
  最近分析: '최근 분석',
  风险概况: '리스크 프로필',
  选择客户: '고객 선택',
  刚刚: '방금',
  chat: {
    思考中: '생각 중',
    思考秒: '{{number}}초 동안 생각 중',
    推荐问: '\n추천 질문:',
    消息异常: '메시지 오류',
  },
  index: {
    总任务数: '전체 작업 수',
    总任务中的总用户数: '전체 작업의 총 사용자 수',
    概览: '개요',
    title: '자산 변동',
    亏损客户数: '손실 계좌',
    盈利客户数: '수익 계좌',
    全部: '전체',
    亏损: '손실',
    盈利: '수익',
  },
  menus: {
    首页: '홈',
    文档: '문서',
    客户: '고객',
    指导: '가이드',
  },
  login: {
    欢迎标题: '아웃바운드 콜 어시스턴트',
    选择角色: '역할을 선택해 주세요',
    登录描述: '아웃바운드 콜 어시스턴트에 오신 것을 환영합니다. 고객 관리와 매출 향상을 도와드립니다!',
    请输入用户名: '사용자 이름을 입력하세요',
    请输入邮箱: '이메일을 입력하세요',
    获取验证码: '인증코드 받기',
    请输入邮箱验证码: '이메일 인증코드를 입력하세요',
    请确认密码: '비밀번호 확인',
    FirstName: '성',
    LastName: '이름',
    请输入手机号: '휴대폰 번호를 입력하세요',
    输入邮箱: '이메일 입력',
    输入密码: '비밀번호 입력',
    请输入密码: '비밀번호를 입력하세요',
    账号密码不正确: '이메일 또는 비밀번호가 올바르지 않습니다',
    注册账号: '계정 등록',
    登录: '로그인',
    注册: '회원가입',
    不能为空: '필수 항목',
    密码规则: '비밀번호는 8자 이상이며, 숫자, 문자, 특수문자를 포함해야 합니다',
    两次输入的密码不一致: '비밀번호가 일치하지 않습니다',
    邮箱格式不正确: '이메일 형식이 올바르지 않습니다',
    注册失败: '등록 실패',
    注册成功: "등록 성공",
    发送验证码失败: '인증코드 전송 중 오류가 발생했습니다. 나중에 다시 시도해 주세요',
    注册失败1: "사용자 이름은 최소 3자 이상이어야 합니다",
    注册失败2: "이미 존재하는 이메일입니다",
    注册失败3: "이미 존재하는 사용자 이름입니다",
    注册失败4: "이미 존재하는 전화번호입니다",
    注册失败5: "전화번호는 숫자여야 합니다",
    注册失败6: "인증코드가 올바르지 않습니다",
  },
  task: {
    所有类型: '모든 유형',
    类型1: '유형1',
    类型2: '유형2',
    所有状态: '모든 상태',
    状态1: '상태1',
    状态2: '상태2',
    搜索: '검색',
    编号: '번호',
    呼叫主题: '통화 주제',
    类型: '유형',
    推荐产品: '추천 제품',
    日期: '날짜',
    状态: '상태',
    操作: '작업',
    新建任务: '새 작업',
    选择任务类型: '작업 유형 선택',
    推销信用卡: '신용카드 판매',
    推销型产品: '판매 상품',
    推销分期付款: '할부 판매',
    投资产品销售: '투자 상품 판매',
    活动名称: '활동명',
    输入活动名称: '활동명 입력',
    取消: '취소',
    开始聊天: '채팅 시작',
    待启动: '대기 중',
    进行中: '진행 중',
    已完成: '완료됨',
    已取消: '취소됨',
    活动名称不能为空: '활동명은 필수 항목입니다',
    呼叫名单: '통화 명단',
    呼叫列表: '통화 목록',
    客户ID: '고객 ID',
    客户姓名: '고객명',
    联系电话: '연락처',
    记录: '기록',
    时间: '시간',
    拨打: '전화걸기',
  },
  call: {
    待拨打: '대기 중',
    通话中: '통화 중',
    已结束: '종료됨',
    未接通: '미연결',
    打印: '프린트',
    下载: '다운로드',
    呼叫名单: '통화 명단',
    用户详情: '사용자 상세',
    呼叫列表: '통화 목록',
    呼叫编组: '통화 그룹',
    客户姓名: '고객명',
    联系电话: '연락처',
    客户ID: '고객 ID',
    通话小结: '통화 요약',
    通话历史: '통화 기록',
  },
  userDetailTable: {
    投资类别: '투자 유형',
    特定投资或证券的名称: '특정 투자 또는 증권명',
    公开交易证券的股票代码或符号: '상장 증권의 종목 코드 또는 기호',
    客户持有的股份或单位数量: '고객 보유 주식 또는 단위 수',
    最初购买时的价格: '최초 구매 가격',
    当前市场价格: '현재 시장 가격',
    总价值: '총 가치',
    原始成本: '원가',
    市场价值与成本基础之间的差额: '시장 가치와 원가의 차이',
    年收益率: '연간 수익률',
    投资收入: '투자 수익',
    总资产分配给此资产的比例: '총 자산 중 해당 자산의 비율',
    风险水平: '위험 수준',
    更新投资组合数据的日期: '포트폴리오 데이터 업데이트 날짜',
  },
  upload: {
    文件类型不支持: '지원하지 않는 파일 형식',
    文件太大: '파일이 너무 큽니다. {{size}}MB를 초과할 수 없습니다',
    上传成功: '업로드 성공',
    上传限制个数: '최대 {{number}}개 파일 업로드 가능',
    拖拽文件至此或者: '파일을 여기로 드래그하거나',
    下载模板: '템플릿 다운로드',
    下载用户数据: '사용자 데이터 다운로드',
    下载理财数据: '재무 데이터 다운로드',
    上传用户或理财信息: '계정 또는 자산 데이터 업로드',
    上传理财信息: '재무 정보 업로드',
    选择文件: '파일 선택',
    支持文件: '{{exp}} 지원, 각 파일 {{size}}MB 이하'
  },
  api: {
    success: '성공',
    actionSuccess: '작업 성공',
    saved: '저장됨',
    create: '생성됨',
    remove: '삭제됨',
  },
}

export default translation
