const translation = {
    chat: {
        思考中: '思考中',
        思考秒: '{{number}}秒思考中',
        推荐问: '\nおすすめの質問:',
        消息异常: 'メッセージの異常',
    },
    index: {
        总任务数: '総タスク数',
        总任务中的总用户数: '総タスクの総ユーザー数',
        概览: '概要',
    },
    menus: {
        首页: 'ホーム',
        文档: 'ドキュメント',
        客户: '顧客',
        指导: 'ガイド',
    },
    login: {
        欢迎标题: '外呼アシスタント', 
        选择角色: '役割を選択してください',
        登录描述: '外呼アシスタントをご利用いただきありがとうございます。顧客管理を簡単にし、販売業績を向上させましょう！',
        请输入用户名: 'ユーザー名を入力してください',
        请输入邮箱: 'メールアドレスを入力してください',
        获取验证码: 'コードを取得',
        请输入邮箱验证码: 'メールアドレスの確認コードを入力してください',
        请确认密码: 'パスワードを確認してください',
        FirstName: '姓',
        LastName: '名',
        请输入手机号: '電話番号を入力してください',
        输入邮箱: 'メールアドレスを入力してください',
        输入密码: 'パスワードを入力してください',
        请输入密码: 'パスワードを入力してください',
        账号密码不正确: 'メールアドレスまたはパスワードが正しくありません',
        注册账号: 'アカウントを登録',
        登录: 'ログイン',
        注册: '登録',
        不能为空: '入力必須です',
        密码规则: 'パスワードは8文字以上で、数字、文字、特殊文字を含む必要があります',
        两次输入的密码不一致: '2回入力したパスワードが一致しません',
        邮箱格式不正确: '無効なメールアドレス形式です',
        注册失败: '登録に失敗しました',
        注册成功: '登録が成功しました',
        发送验证码失败: '確認コードの送信に失敗しました。後でもう一度お試しください',
        注册失败1: 'ユーザー名は3文字以上でなければなりません',
        注册失败2: 'メールアドレスはすでに存在します',
        注册失败3: 'ユーザー名はすでに存在します',
        注册失败4: '電話番号はすでに存在します',
        注册失败5: '電話番号は数値である必要があります',
        注册失败6: 'コードが誤っています',
    },
    task: {
        所有类型: 'すべてのタイプ',
        类型1: 'タイプ1',
        类型2: 'タイプ2',
        所有状态: 'すべての状態',
        状态1: '状態1',
        状态2: '状態2',
        搜索: '検索',
        编号: '番号',
        呼叫主题: 'コールテーマ',
        类型: 'タイプ',
        推荐产品: '推奨製品',
        日期: '日付',
        状态: '状態',
        操作: '操作',
        新建任务: '新しいタスクを作成',
        选择任务类型: 'タスクタイプを選択',
        推销信用卡: 'クレジットカードの販売',
        推销型产品: '販売型製品',
        推销分期付款: '分割払いの販売',
        投资产品销售: '投資製品の販売',
        活动名称: 'アクティビティ名',
        输入活动名称: 'アクティビティ名を入力してください',
        取消: 'キャンセル',
        开始聊天: 'チャットを開始',
        待启动: '開始待ち',
        进行中: '進行中',
        已完成: '完了',
        已取消: 'キャンセル済み',
        活动名称不能为空: 'アクティビティ名は空にできません',
        呼叫名单: 'コールリスト',
        呼叫列表: 'コールリスト',
        客户ID: '顧客ID',
        客户姓名: '顧客名',
        联系电话: '連絡先電話番号',
        记录: '記録',
        时间: '時間',
        拨打: '電話をかける',
    },
    call: {
      待拨打: '発信待ち',
      通话中: '通話中',
      已结束: '終了',
      未接通: '不通',
      打印: '印刷',
      下载: 'ダウンロード',
      呼叫名单: 'コールリスト',
      呼叫编组: 'コールグループ',
      用户详情: 'ユーザーの詳細',
      呼叫列表: 'コールリスト',
      客户姓名: '顧客名',
      联系电话: '連絡先電話番号',
      客户ID: '顧客ID',
      通话小结: '通話小結',
      通话历史: '通話歴史',
    },
    api: {
      success: '成功',
      actionSuccess: '操作成功',
      saved: '保存済み',
      create: '作成済み',
      remove: '削除済み',
    },
}

export default translation