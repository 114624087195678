const translation = {
  分析: 'تحليل',
  所属经理: 'المدير المسؤول',
  投资策略: 'استراتيجية الاستثمار',
  目标: 'الهدف',
  状态: 'الحالة',
  建立日期: 'تاريخ الإنشاء',
  最近分析: 'التحليل الأخير',
  风险概况: 'ملف المخاطر',
  选择客户: 'اختيار العميل',
  刚刚: 'الآن',
  chat: {
    思考中: 'جاري التفكير',
    思考秒: 'يفكر منذ {{number}} ثانية',
    推荐问: '\nأسئلة مقترحة:',
    消息异常: 'رسالة غير طبيعية',
  },
  index: {
    总任务数: 'إجمالي المهام',
    总任务中的总用户数: 'إجمالي المستخدمين في المهام',
    概览: 'نظرة عامة',
    title: 'تغيرات الأصول',
    亏损客户数: 'حسابات خاسرة',
    盈利客户数: 'حسابات رابحة',
    全部: 'الكل',
    亏损: 'خسارة',
    盈利: 'ربح',
  },
  menus: {
    首页: 'الرئيسية',
    文档: 'المستندات',
    客户: 'العملاء',
    指导: 'التوجيه',
  },
  login: {
    欢迎标题: 'مساعد المكالمات الصادرة',
    选择角色: 'الرجاء اختيار دور، أنت',
    登录描述: 'مرحباً بك في مساعد المكالمات الصادرة، لمساعدتك في إدارة العملاء وتحسين المبيعات!',
    请输入用户名: 'الرجاء إدخال اسم المستخدم',
    请输入邮箱: 'الرجاء إدخال البريد الإلكتروني',
    获取验证码: 'الحصول على رمز التحقق',
    请输入邮箱验证码: 'الرجاء إدخال رمز التحقق',
    请确认密码: 'تأكيد كلمة المرور',
    FirstName: 'الاسم الأول',
    LastName: 'اسم العائلة',
    请输入手机号: 'الرجاء إدخال رقم الهاتف',
    输入邮箱: 'أدخل بريدك الإلكتروني',
    输入密码: 'أدخل كلمة المرور',
    请输入密码: 'الرجاء إدخال كلمة المرور',
    账号密码不正确: 'البريد الإلكتروني أو كلمة المرور غير صحيحة',
    注册账号: 'تسجيل حساب جديد',
    登录: 'تسجيل الدخول',
    注册: 'تسجيل',
    不能为空: 'مطلوب',
    密码规则: 'يجب أن تحتوي كلمة المرور على 8 أحرف على الأقل وتتضمن أرقاماً وحروفاً ورموزاً خاصة',
    两次输入的密码不一致: 'كلمات المرور غير متطابقة',
    邮箱格式不正确: 'صيغة البريد الإلكتروني غير صحيحة',
    注册失败: 'فشل التسجيل',
    注册成功: "تم التسجيل بنجاح",
    发送验证码失败: 'حدث خطأ أثناء إرسال رمز التحقق، يرجى المحاولة لاحقاً',
    注册失败1: "يجب أن يكون اسم المستخدم 3 أحرف على الأقل",
    注册失败2: "البريد الإلكتروني مستخدم بالفعل",
    注册失败3: "اسم المستخدم مستخدم بالفعل",
    注册失败4: "رقم الهاتف مستخدم بالفعل",
    注册失败5: "يجب أن يكون رقم الهاتف أرقاماً",
    注册失败6: "رمز التحقق غير صحيح",
  },
  task: {
    所有类型: 'جميع الأنواع',
    类型1: 'النوع 1',
    类型2: 'النوع 2',
    所有状态: 'جميع الحالات',
    状态1: 'الحالة 1',
    状态2: 'الحالة 2',
    搜索: 'بحث',
    编号: 'الرقم',
    呼叫主题: 'موضوع المكالمة',
    类型: 'النوع',
    推荐产品: 'المنتجات الموصى بها',
    日期: 'التاريخ',
    状态: 'الحالة',
    操作: 'العمليات',
    新建任务: 'مهمة جديدة',
    选择任务类型: 'اختر نوع المهمة',
    推销信用卡: 'تسويق البطاقات الائتمانية',
    推销型产品: 'المنتجات التسويقية',
    推销分期付款: 'تسويق التقسيط',
    投资产品销售: 'بيع المنتجات الاستثمارية',
    活动名称: 'اسم النشاط',
    输入活动名称: 'أدخل اسم النشاط',
    取消: 'إلغاء',
    开始聊天: 'بدء المحادثة',
    待启动: 'في انتظار البدء',
    进行中: 'قيد التنفيذ',
    已完成: 'مكتمل',
    已取消: 'ملغي',
    活动名称不能为空: 'اسم النشاط مطلوب',
    呼叫名单: 'قائمة المكالمات', // Corrected to match the Arabic context
    呼叫列表: 'قائمة المكالمات',
    客户ID: 'معرف العميل',
    客户姓名: 'اسم العميل',
    联系电话: 'رقم الاتصال',
    记录: 'السجل',
    时间: 'الوقت',
    拨打: 'اتصال',
  },
  call: {
    待拨打: 'في انتظار الاتصال',
    通话中: 'جاري الاتصال',
    已结束: 'انتهى',
    未接通: 'لم يتم الرد',
    打印: 'طباعة',
    下载: 'تحميل',
    呼叫名单: 'قائمة المكالمات',
    用户详情: 'تفاصيل المستخدم',
    呼叫列表: 'قائمة المكالمات', 
    呼叫编组: 'رقم المجموعة',
    客户姓名: 'اسم العميل',
    联系电话: 'رقم الاتصال',
    客户ID: 'معرف العميل',
    通话小结: 'ملخص المكالمة',
    通话历史: 'تاريخ المكالمات',
  },
  userDetailTable: {
    投资类别: 'فئة الاستثمار',
    特定投资或证券的名称: 'اسم الاستثمار أو الأوراق المالية المحددة',
    公开交易证券的股票代码或符号: 'رمز أو رمز الأوراق المالية المتداولة علناً',
    客户持有的股份或单位数量: 'عدد الأسهم أو الوحدات التي يمتلكها العميل',
    最初购买时的价格: 'سعر الشراء الأولي',
    当前市场价格: 'السعر الحالي في السوق',
    总价值: 'القيمة الإجمالية',
    原始成本: 'التكلفة الأصلية',
    市场价值与成本基础之间的差额: 'الفرق بين القيمة السوقية وأساس التكلفة',
    年收益率: 'معدل العائد السنوي',
    投资收入: 'الدخل من الاستثمار',
    总资产分配给此资产的比例: 'نسبة إجمالي الأصول المخصصة لهذا الأصل',
    风险水平: 'مستوى المخاطر',
    更新投资组合数据的日期: 'تاريخ تحديث بيانات المحفظة',
  },
  upload: {
    文件类型不支持: 'نوع الملف غير مدعوم',
    文件太大: 'الملف كبير جداً، يجب ألا يتجاوز {{size}} ميجابايت',
    上传成功: 'تم الرفع بنجاح',
    上传限制个数: 'الحد الأقصى للرفع {{number}} ملفات',
    拖拽文件至此或者: 'اسحب الملفات هنا أو',
    下载模板: 'تحميل القالب',
    下载用户数据: 'تحميل بيانات المستخدم',
    下载理财数据: 'تحميل البيانات المالية',
    上传用户或理财信息: 'رفع بيانات الحساب أو الثروة',
    上传理财信息: 'رفع المعلومات المالية',
    选择文件: 'اختر ملفاً',
    支持文件: 'يدعم {{exp}}، حجم كل ملف لا يتجاوز {{size}} ميجابايت'
  },
  api: {
    success: 'نجاح',
    actionSuccess: 'تمت العملية بنجاح',
    saved: 'تم الحفظ',
    create: 'تم الإنشاء',
    remove: 'تمت الإزالة',
  },
}

export default translation
